import favicon16 from '../img/favicon-v1/favicon-16x16.v2.png';
import faviconSVG16 from '../img/favicon-v1/favicon-16x16.v2.svg';
import favicon32 from '../img/favicon-v1/favicon-32x32.v2.png';
import favicon180 from '../img/favicon-v1/favicon-180x180.v2.png';
import favicon192 from '../img/favicon-v1/favicon-192x192.v2.png';

import volvoLogo from '../img/volvo-logo.svg';

import facebookIcon from '../img/facebook.svg';
import instagramIcon from '../img/instagram.svg';
import linkedinIcon from '../img/linkedin.svg';
import youtubeIcon from '../img/youtube.svg';
import twitterIcon from '../img/twitter.svg';

import errorIcon from '../img/errorIcon.svg';
import warningIcon from '../img/warningIcon.svg';
import successIcon from '../img/successIcon.svg';

import playIcon from '../img/play.svg';
import arrowIcon from '../img/arrow.svg';

import heroImageScreenshot from '../img/acf-blocks-previews/heroImage.png';
import twoColumnContentComponentScreenshot from '../img/acf-blocks-previews/twoColumnContentComponent.png';
import galleryComponentScreenshot from '../img/acf-blocks-previews/gallery.png';
import textEditorExtended from '../img/acf-blocks-previews/textEditorExtended.png';


import notFoundImg from '../img/not-found-img.png';

import {importAll} from "./importAll";

const volvoIcons = importAll(require.context('../img/volvo-icons', false, /\.(png|jpe?g|svg)$/));

import './lazyloading';
import './scrollToSection';

import '../../../includes/views/components/atoms/checkbox/checkbox';
import '../../../includes/views/components/atoms/input/input';
import '../../../includes/views/components/atoms/input-range/input-range';
import '../../../includes/views/components/atoms/select/select-multi';
import '../../../includes/views/components/atoms/textarea/textarea';
import '../../../includes/views/components/atoms/tooltip/tooltip';

import '../../../includes/views/components/organisms/form/form';
import '../../../includes/views/components/organisms/cookies/cookies';
import '../../../includes/views/components/atoms/video/video';
import '../../../includes/views/components/organisms/gallery/gallery';
import '../../../includes/views/components/molecules/full-size-gallery/fullSizeGallery';
import '../../../includes/views/components/organisms/header/header';
import '../../../includes/views/layouts/side-form/sideForm'; 

